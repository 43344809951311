<template>
  <div class="userManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='userManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="用户管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="姓名">
                <el-input v-model="formInline.realName" placeholder="姓名" clearable></el-input>
              </el-form-item>
              <el-form-item label="用户状态">
                <el-select v-model="formInline.lockFlag" placeholder="选择用户状态" clearable>
                  <el-option label="正常" value="ENABLE"></el-option>
                  <el-option label="禁用" value="DISABLE"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="管理省份">
                <el-select v-model="formInline.provinceId" placeholder="选择管理省份" filterable clearable>
                  <el-option
                    v-for="item in provinceData"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="角色">
                <el-select v-model="formInline.roleId" placeholder="选择角色" filterable clearable>
                  <el-option
                    v-for="item in roleOptions"
                    :key="item.roleId"
                    :label="item.roleName"
                    :value="item.roleId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker
                  @change="timeEvent"
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="管理学校">
                <el-select v-model="formInline.schoolId" placeholder="选择管理学校" clearable>
                </el-select>
              </el-form-item> -->
              <!-- <el-form-item>
                <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
                <el-button type="info" plain @click="resetEvent">重置</el-button>
              </el-form-item> -->
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="versionEvent('add')" plain v-allow="'sys_user_add'">+添加成员</el-button>
            <el-button class="mainBtn" @click="batchOperaEvent('ENABLE')" plain v-allow="'sys_user_edit'">批量启用</el-button>
            <el-button class="mainBtn" @click="batchOperaEvent('DISABLE')" plain v-allow="'sys_user_edit'">批量禁用</el-button>
            <el-button class="mainBtn" @click="batchDelete" plain v-allow="'sys_user_del'">批量删除</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key='userId'
            @selection-change="handleSelectionChange">
            <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55">
            </el-table-column>
            <el-table-column
              prop="realName"
              label="姓名"
              align="center"
              width="130">
            </el-table-column>
            <el-table-column
              prop="userName"
              label="用户账号"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.userName" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.userName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="roleNames"
              label="角色"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.roleNames" placement="top" effect="light" popper-class="pubToop">
                  <div @click="versionEvent('edit',scope.row)" class="ellipsis jumpEdit">{{scope.row.roleNames || "/"}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="provinces"
              label="管理省份数量"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.provinces" placement="top" effect="light" popper-class="pubToop">
                  <!-- <div class="ellipsis">{{scope.row.provinces}}</div> -->
                  <div class="ellipsis jumpEdit" @click="versionEvent('edit',scope.row)">
                    {{getProNum(scope.row) || 0}}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="schools"
              label="管理学校"
              align="center"
              show-overflow-tooltip>
            </el-table-column> -->
            <el-table-column
              prop="lockFlag"
              label="状态"
              align="center"
              width="180">
              <template slot-scope="scope">
                {{scope.row.lockFlag == 'ENABLE' ? '启用' : '禁用'}}
                <el-switch
                  v-allow="'sys_user_edit'"
                  v-model="scope.row.lockFlag"
                  active-value="ENABLE"
                  inactive-value="DISABLE"
                  @change="chageRoleStatus(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="160"
              align="center">
              <template slot-scope="scope">
                <el-button size="small" type="text" @click="versionEvent('edit',scope.row)" v-allow="'sys_user_edit'">编辑</el-button>
                <el-button size="small" type="text" @click="resetPass(scope.row)">重置密码</el-button>
                <el-button size="small" @click="deleteEvent(scope.row)" type="text" v-allow="'sys_user_del'">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>

    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="">
        <p>{{deleteType == 1 ? '确定要删除该用户吗？' : deleteType == 2 ? '确定要批量删除用户吗？' :deleteType == 3 ? '确定要批量启用用户吗？' : '确定要批量禁用用户吗？'}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>

    <el-dialog class="compile" title="重置密码" :visible.sync="isShowVer">
      <div class="cont_pass">确定要重置该用户的密码？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowVer = false">取 消</el-button>
        <el-button type="primary" @click="enterEvent">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
import way from '../../api/encapsulation'
export default {
  name: 'userManagement',
  components: {
    publicChildren,
    Pagination,
    dialogDiy,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        createTimeEnd: null,
        createTimeStart: null,
        lockFlag: null,
        page: 1,
        pageSize: 10,
        provinceId: null,
        realName: null,
        roleId: null
      },
      tableData: [],
      roleListData: [], // 用户列表数据
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      timeVal: '',
      idArr: [],
      moduleData: [],
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
      deleteType: 1, // 1 单个删除， 2 批量删除
      isShowVer: false,
      form: {
        roleName: '',
        remarks: '',
        lockFlag: ''
      },
      isType: false,
      userId: '', // 用户id
      roleOptions: [],
      provinceData: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'userManagement') {
          this.getUserTableEvent();
          this.getRoleDownEvent();
          this.getProviceEvent();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    getProNum(row) {
      if(row.provinces) {
        let arr = row.provinces.split(',');
        // console.log(arr)
        return arr.length;
      }
      // return;
    },
    // 获取平台用户表格数据
    getUserTableEvent() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getUserTable(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    // 获取角色名称数据
    getRoleDownEvent() {
      this.$request.getRoleDown({}, res=>{
        if(res.code == 0) {
          this.roleOptions = res.data;
        }
      })
    },
    // 获取省份
    getProviceEvent() {
      this.$request.getProviceNew({}, res => {
        if(res.code == 0) {
          this.provinceData = res.data;
        }
      })
    },
    regRoleList(row) {
      let val = '';
      if(row.roleList.length > 0) {
        val = row.roleList.join('、');
      }
      return val;
    },
    regProvinces(row) {
      let val = '';
      if(row.provinces.length > 0) {
        val = row.provinces.join('、');
      }
      return val;
    },
    timeEvent(val) {
      if(!val) {
        this.formInline.createTimeStart = '';
        this.formInline.createTimeEnd = '';
      }
    },
    batchOperaEvent(type) {
      this.deleteType = type == 'ENABLE' ? 3 : 4;
      this.isShow = !this.isShow;
    },
    handleSelectionChange(val) {
      let idArr = [];
      if(val.length > 0){
        idArr = val.map((item, index) => {
          return item.userId
        })
      } else {
        idArr = val;
      }
      this.idArr = idArr;
    },
    chageRoleStatus(row) {
      let obj = {
        id: row.userId
      }
      if(row.lockFlag == 'DISABLE') {
        this.$request.disableUser(obj).then(res => {
          if(res.data.code == 0){
            this.Success(res.data.msg)
          }
        }).catch(err => {
          row.lockFlag = 'ENABLE'
        })
      } else {
        this.$request.enableUser(obj).then(res=>{
          if(res.data.code == 0) {
            this.Success(res.data.msg)
          }
        }).catch(err => {
          row.lockFlag = 'DISABLE'
        })
      }
      
    },
    // 重置
    resetEvent() {
      this.formInline.realName = null;
      this.formInline.lockFlag = null;
      this.formInline.provinceId = null;
      this.formInline.roleId = null;
      this.formInline.createTimeStart = null;
      this.formInline.createTimeEnd = null;
      this.timeVal = '';
    },
    onSubmit() {
      if(this.timeVal) {
        this.formInline.createTimeStart = way.dateReturnTimestamp(this.timeVal[0]);
        this.formInline.createTimeEnd = way.dateReturnTimestamp(this.timeVal[1]);
      }
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getUserTableEvent();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getUserTableEvent();
    },
    // 批量删除
    batchDelete() {
      this.deleteType = 2;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    // 删除
    deleteEvent(row) {
      this.deleteType = 1;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      if(this.deleteType == 1) {
        this.onlyDelete();
      } else if(this.deleteType == 2) {
        this.moreDelete();
      } else if(this.deleteType == 3) {
        this.batchEnable();
      } else {
        this.batchDisable();
      }
    },
    // 批量启用
    batchEnable() {
      if(this.idArr.length == 0) {
        this.Warn('请选择数据');
        return;
      }
      let ids = this.idArr;
      this.$request.enableUsers({ids}).then(res=>{
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.page = 1;
          this.getUserTableEvent();
        }
      })
    },
    // 批量禁用
    batchDisable() {
      if(this.idArr.length == 0) {
        this.Warn('请选择数据');
        return;
      }
      let ids = this.idArr;
      this.$request.disableUsers({ids}).then(res=>{
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.page = 1;
          this.getUserTableEvent();
        }
      })
    },
    // 单个删除执行事件
    onlyDelete() {
      let id = this.rowStatus.userId;
      this.$request.deleteByUserId({id}, res => {
        if(res.code == 0) {
          this.isShow = !this.isShow;
          this.rowStatus = '';
          this.Success(res.msg);
          this.formInline.page = 1;
          this.getUserTableEvent();
        }
      })
    },
    // 批量删除执行事件
    moreDelete() {
      if(this.idArr.length == 0) {
        this.Warn('未选择需批量删除的数据');
        return;
      }
      let ids = this.idArr;
      this.$request.deleteByUserIdList({ids}).then( res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.page = 1;
          this.getUserTableEvent();
        }
      })
    },
    versionEvent(type, row = {}) {
      if(type == 'add') {
        this.$router.push({name: 'addUser', query: {
          type
        }});
      } else {
        if(!this.$isShowModle('sys_user_edit')) {
          this.Warn('没有编辑权限')
          return;
        }
        this.$router.push({name: 'addUser', query: {
          type,
          userId: row.userId
        }});
      }
    },
    editData(row) {
      this.form.roleName = row.roleName;
      this.form.remarks = row.remarks;
      this.form.lockFlag = row.lockFlag;
      this.rowStatus = row;
    },
    resetPass(row) {
      this.userId = row.userId;
      this.isShowVer = !this.isShowVer;
    },
    enterEvent() {
      this.$request.resetUserPassword({userId: this.userId}).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
        } else {
          this.Warn(res.data.msg)
        }
        this.isShowVer = false;
      })
    },
  },
}
</script>

<style lang="less">
  .userManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .dia_opera_role {
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
    }
    .compile {
      .el-dialog {
        width: 400px;
      }
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .jumpEdit {
      color: #409EFF;
      cursor: pointer;
    }
  }
  .pop_area {
    text-align: center;
  }
  .pubToop {
    max-width: 600px;
  }
</style>